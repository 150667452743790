import "core-js/modules/es.number.constructor.js";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    totalNum: {
      type: Number,
      required: true
    },
    pagesize: {
      type: Number,
      default: 10
    }
  },
  emits: ["changPage"],
  setup: function setup(props, context) {
    function handleCurrentChange(val) {
      context.emit("changPage", val);
    }

    return {
      handleCurrentChange: handleCurrentChange,
      props: props
    };
  }
});