import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_pagination = _resolveComponent("el-pagination");

  return _openBlock(), _createBlock(_component_el_pagination, {
    background: "",
    layout: "prev, pager, next",
    total: _ctx.totalNum,
    class: "pagination",
    "page-size": _ctx.pagesize,
    onCurrentChange: _ctx.handleCurrentChange
  }, null, 8, ["total", "page-size", "onCurrentChange"]);
}